.d_timeline{margin: 0px; padding: 0px; list-style: none; width: 100%; float: left; position: relative;
	li{position: relative; float: left; width: 100%; padding-bottom: 20px;
		.content{width: 44%;
			&.left{float: left; text-align: right;}
			&.right{float: right;}
		}
		&::before{content: ''; display: block; position: absolute; width: 0.9rem; height: 0.9rem; border-radius: 50%; background: $pending; left: calc(50% - 0.45rem); }
		&:last-child{padding-bottom: 0px;}
		&.completed::before{background-color: $completed;}
		&.inprogress::before{background-color: $inprogress;}
		&.completed-late::before{background-color: $completedlate;}
		&.arrow::after{content: ''; display: block; position: absolute; left: calc(50% - 0.4rem); top:50%; width: 0px; height: 0px; border-left: 0.4rem solid transparent;
				border-right:0.4rem solid transparent; border-top:0.6rem solid $pending;
		}
	}
	&::before{content: ''; display: block; position: absolute; left:calc(50% - 0.05rem); width: 1px; height: calc(100% - 1rem); border-left: dotted 0.1rem $pending; top:10px}

}

.d_timeline_status{margin: 0px; padding: 0px; list-style: none; width: 100%; float: left; position: relative;
	li{position: relative; float: left; width: 100%; padding-bottom: 1rem; line-height: 1;
		i{width: 0.9rem; height: 0.9rem; background:$pending; border-radius: 50%; display: block; float:left; margin-right: 0.5rem;}
		&.completed i{background-color: $completed;}
		&.inprogress i{background-color: $inprogress;}
		&.completed-late i{background-color: $completedlate;}
	}
}


//order timeline

.d_timeline_status_latest {
	margin: 0px;
	padding: 0px;
	list-style: none;
	width: 100%;
	float: left;
	position: relative;

	li {
		position: relative;
		float: left;
		width: 100%;
		padding-bottom: 1rem;
		line-height: 1;

		i {
			width: 0.9rem;
			height: 0.9rem;
			background: $pending;
			border-radius: 50%;
			display: block;
			float: left;
			margin-right: 0.5rem;
		}

		&.completed i {
			background-color: $completed;
		}

		&.inprogress i {
			background-color: $inprogress;
		}

		&.completed-late i {
			background-color: $completedlate;
		}

		&.na i {
			background-color: #fff;
			border: solid 1px #808080
		}

		&.negative-flow i {
			background-color: #100ad1;
			border: solid 1px #808080
		}
	}

	&.cus-right {
		justify-content: flex-end !important;
		width: auto !important;
		float: right;

		li {
			width: auto !important;
			padding: 5px 7px 20px 7px !important;
		}
	}
}

.order-timeliness {
	ul {
		padding: 0px;
		margin-bottom: 0px;
		display: block;
		width: 100%;
		float: left;

		li {
			list-style-type: none;
			position: relative;
			width: 100%;
			float: left;
			min-height: 70px;

			.negativeflow {
				margin-top: -19px;
				padding-bottom: 20px;
				display: block;

				.singlenegative {
					// min-height: 40px;
					padding-bottom: 3px !important;

					&::after {
						content: unset;
					}
				}

				&:last-child {
					padding-bottom: 0px !important;
				}
			}

			.order-left {
				width: 50%;
				position: relative;
				float: left;
				padding-right: 50px;
				text-align: right;

				&::before {
					content: "";
					position: absolute;
					top: 0px;
					right: -7px;
					background-color: #adadad;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					z-index: 9;
				}

				&.completed::before {
					background-color: #00aa4c;
					;
				}

				&.inprogress::before {
					background-color: #d24350;
					;
				}

				&.completed-late::before {
					background-color: #ff8800;
				}

				&.pending::before {
					background-color: #adadad;
				}

				&.revert::before {
					background-color: #1b27d3;
				}
			}

			.order-right {
				width: 50%;
				position: relative;
				float: right;
				text-align: start;
				padding-left: 50px;
			}

			.order-status {
				width: 50%;
				position: relative;
				padding-right: 50px;
				border-bottom: 0.1rem dotted #0000ff;
				text-align: left;
			}

			.status-line {
				width: 50%;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					top: 50%;
					right: -7px;
					transform: translate(0px, -6px);
					background-color: #0000ff;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					z-index: 9;
				}
			}

			&::after {
				content: "";
				position: absolute;
				top: 0px;
				left: 50%;
				height: 100%;
				border-left: 0.1rem dotted #adadad;
			}

			&:last-child::after {
				height: 25%;
			}
		}
	}
}